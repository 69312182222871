<template>
  <div class="index">
    <div class="banner">
      <img src="@/assets/product/product_banner@2x.png" alt="">
    </div>
    <div class="main-content">
      <div class="content d-flex justify-content-center border-bottom">
        <div class="row container d-flex justify-content-between align-items-center ">
          <div class="col-xl-5 col-sm-12">
            <div class="paragraph d-flex align-items-start">
              <a-icon class="icon-question" type="question" />
              <div>
                <p>长期以来，学校管理人员难以找到合理可靠的教学效果量化评价指标：</p>
                <p>难以评估单科任课老师的教学效果和对升学指标的贡献度。</p>
              </div>
            </div>
            <div class="paragraph d-flex align-items-start">
              <a-icon class="icon-answer" type="bulb" />
              <div>
                <p>齐进步独创B值，XB值量化评价指标体系：</p>
                <p>体系已经过学校的长期使用与验证，更科学、更合理、更准确。</p>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-12">
            <img src="@/assets/product/product_01_pic@2x.png" alt="">
          </div>
        </div>  
      </div>

      <div class="content d-flex justify-content-center border-bottom">
        <div class="row container d-flex justify-content-between align-items-center">
          <div class="col-xl-6 col-sm-12">
            <img src="@/assets/product/product_02_pic@2x.png" alt="">
          </div>
          <div class="col-xl-5 col-sm-12">
            <div class="paragraph d-flex align-items-start">
              <a-icon class="icon-question" type="question" />
              <div>
                <p>目前通用的最终升学指标等考核指标不够合理：教学效果评价的核心是过程评价，而不是最终单一结果评价；</p>
                <p>最终升学指标是单一最终结果指标，而教学效果评价是一个典型的过程考核。</p>
              </div>
            </div>
            <div class="paragraph d-flex align-items-start">
              <a-icon class="icon-answer" type="bulb" />
              <div>
                <p>齐进步独创的升学指标跟踪法：</p>
                <p>将最终升学指标完成率分拆到三年周期的每次考试中，同时将跟踪每次考试的完成率。</p>
              </div>
            </div>
          </div>
        </div>  
      </div>

      <div class="content d-flex justify-content-center border-bottom">
        <div class="row container d-flex justify-content-between align-items-center">
          <div class="col-xl-5 col-sm-12">
            <div class="paragraph d-flex align-items-start">
              <a-icon class="icon-question" type="question" />
              <div>
                <p>要想实现过程考核，对人员信息管理也提出了更高的要求：</p>
                <p>教师信息不稳定；</p>
                <p>学生学籍信息不稳定；</p>
                <p>传统的手动归档整理每次考试的数据文件不完善，不可靠，不系统。</p>
              </div>
            </div>
            <div class="paragraph d-flex align-items-start">
              <a-icon class="icon-answer" type="bulb" />
              <div>
                <p>齐进步信息聚合管理系统：</p>
                <p>更系统更准确的管理三方信息。</p>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-12">
            <img src="@/assets/product/product_03_pic@2x.png" alt="">
          </div>
        </div>  
      </div>

      <div class="content d-flex justify-content-center">
        <div class="row container d-flex justify-content-between align-items-center">
          <div class="col-xl-6 col-sm-12">
            <img src="@/assets/product/product_04_pic@2x.png" alt="">
          </div>
          <div class="col-xl-5 col-sm-12">
            <div class="paragraph d-flex align-items-start">
              <a-icon class="icon-question" type="question" />
              <div>
                <p>单一定量绩效指标体系难以具备公信力：</p>
                <p>优生班和后进生班的教室考核难以兼顾；</p>
                <p>学生学籍信息不稳定；</p>
                <p>缺乏公平公开的公示系统，数据计算过程难以保证透明可查。</p>
              </div>
            </div>
            <div class="paragraph d-flex align-items-start">
              <a-icon class="icon-answer" type="bulb" />
              <div>
                <p>齐进步多维动态评价体系：</p>
                <p>兼顾学生基础水平，进步幅度，最终升学指标完成率等多维评价指标；</p>
                <p>所有老师均可随时登录查询，数据计算底层依据清晰可追溯，创造能够让全校教师认可的综合评价指标体系。</p>
              </div>
            </div>
          </div>
        </div>  
      </div>
     
    </div>
  </div>
  
</template>
<script>
export default {
  mounted () {
    // this.$message.success('222')
  },
}
</script>
<style lang="less" scoped>
  .banner{
    img{
      width:100%;
    }
  }
  .main-content{
    .content{
      padding:70px 0;
      img{
        display:block;
        max-width:100%;
      }
    }
    .paragraph{
      margin-bottom:30px;
      p{
        font-size: 20px;
        margin-bottom:0;
        line-height:2;
        color:#00255E;
      }
      .icon-question,.icon-answer{
        display:block;
        width:30px;
        min-width: 30px;
        height: 30px;
        line-height:30px;
        background: linear-gradient(180deg, #2260CA 0%, #6A99EB 100%);
        color:#fff;
        border-radius:35px;
        margin-right:10px;
        margin-top:5px;
      }
      .icon-answer{
        background: linear-gradient(180deg, #EC6300 0%, #FFC69C 100%);
      }
    }

  }
</style>